import React, { useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@apollo/client';
import common from '../../../shared/styles/common.module.scss';
import { SearchOutlined } from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Input,
    InputRef,
    Popover,
    Space,
    Table,
    Tag,
    DatePicker,
    Modal,
} from 'antd';
import numberWithSpaces from '../../../shared/utils/utils';
import { CartEventsTable } from './CartEventsTable';
import s from '../styles.module.scss';
import { cellsCount, maximumProductsPricesLength } from './utils';
import Paragraph from 'antd/es/typography/Paragraph';
import PercentageLoader from '../../../New/shared-components/ui/Loaders/PercentageLoader';
import dayjs from 'dayjs';
import {
    graphqlClientSuperAdmin,
    LinkType,
} from '../../../shared/graphql/clients';
import { ADMIN_GET_CART_METRICS } from '../../../shared/graphql/superadmin/graphql';
import {
    CartOrderMetricsQuery,
    CartOrderMetricsQueryVariables,
    ProductMetrics,
    SingleMetrics,
} from '../../../shared/graphql/superadmin/generated/graphql.types';
import { prepareStarsForView } from '../../../shared/utils/prepareStarsForView';
import { CartTypeEnum } from '../../../shared/types';

const desc = {
    cartID: 'Идентификатор корзины',
    orderID: 'Идентификатор заказа',
    cartSize: 'Количество товаров в корзине',
    datetime: 'Первое и последнее действие в корзине',
    cartPrice: 'Сумма товаров в корзине',
    tradeDiscount: 'Процент скидки предложенной на торге цены',
    discount: 'Средняя скидка товаров корзины',
    steps: 'Число шагов',
    displayPriceLTPriceCount:
        'Количество товаров c display_price < price в каталоге на последнем шаге',
    gain: 'Конечный gain',
    checkGap: 'Конечный checkGap',
    checkMD: 'Конечный checkMD',
    displayPrice: 'Конечные цены',
    deltaTime: 'Время старта',
    productsInCartBeforeTrade: 'Количество товаров в корзине до торга',
    totalBonus: 'Суммарный бонус у купленной корзины',
    juke: 'juke',
};

const descWithoutCart = {
    orderID: 'Идентификатор заказа',
    date: 'Дата',
    count: 'Количество',
    displayPrice: 'Отображаемая цена',
    threshold: 'Порог цены',
    mD: 'Максимальная скидка от цены в рублях',
    bonus: 'Бонус за позицию в заказе',
};

export const ActionColor = {
    AddToCart: 'green',
    AddAggregatedProduct: 'green',
    Trade: 'blue',
    Deactivate: 'red',
    Plus: 'cyan',
    Minus: 'orange',
};

export const ActionAlias = {
    AddToCart: 'Add',
    AddAggregatedProduct: 'Add',
    Trade: 'Trade',
    Deactivate: 'Deactivate',
    Plus: 'Plus',
    Minus: 'Minus',
};

const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
};

const handleReset = (clearFilters) => {
    clearFilters();
};

export const getPrice = (price) => {
    return numberWithSpaces(price || 0);
};

export const getFinalDisplayPrices = (
    products: any[] = [],
    cellCount: number,
    cartType: CartTypeEnum
) => {
    const productCells: any[] = [];
    for (let i = 0; i < cellCount; i++) {
        productCells.push(products[i] || null);
    }

    return (
        <table className={s.PricesTable}>
            <tr>
                {productCells?.map((product, index) => (
                    <td key={product?.id || index}>
                        {product?.displayPrice ? (
                            <>
                                <span>{`${getPrice(
                                    product.displayPrice
                                )}`}</span>
                                {product?.current && <> (Current)</>}
                                <br />
                            </>
                        ) : null}
                        {cartType === 'star' ? (
                            <>
                                Stars:{' '}
                                {product?.stars ? prepareStarsForView(product.stars) : '-'} (
                                {product?.cartStars? prepareStarsForView(product.cartStars) : '-'})
                            </>
                        ) : null}
                    </td>
                ))}
            </tr>
        </table>
    );
};

export const getFinalDisplayPricesForCartTypeEvents = (
    index: number,
    displayPricesTable: any[][],
    cellCount: number,
    cartType?: CartTypeEnum
) => {
    const productCells: (ProductMetrics & {
        index: number;
        event: SingleMetrics;
    })[] = [];
    for (let i = 0; i < cellCount; i++) {
        productCells.push(displayPricesTable[index][i] || null);
    }

    return (
        <table className={s.PricesTable}>
            <tr>
                {productCells?.map((product, index) => (
                    <td key={product?.aggregationSign || index}>
                        {product?.displayPrice ? (
                            <>
                                <span>{`${getPrice(
                                    product.displayPrice
                                )}`}</span>
                                {product?.current && <> (Current)</>}
                                <br />
                            </>
                        ) : null}
                        {cartType === 'star' ? (
                            <>
                                Stars:{' '}
                                {product?.stars ? prepareStarsForView(product.stars) : '-'} (
                                {product?.cartStars? prepareStarsForView(product.cartStars) : '-'})
                            </>
                        ) : null}
                    </td>
                ))}
            </tr>
        </table>
    );
};

export const groupBy = <T,>(
    list: T[],
    keyGetter: (item: T) => any
): Record<string, T[]> => {
    const map = new Map();
    list.forEach((item) => {
        const key = keyGetter(item) || 'withoutCart';
        const collection = map.get(key);
        if (!collection) {
            map.set(key, [item]);
        } else {
            collection.push(item);
        }
    });
    return Object.fromEntries(map.entries());
};

export const getFinalStepForCartTypeEvents = (
    products: ProductMetrics[],
    cellCount: number,
    cartType?: CartTypeEnum
) => {
    const productCells: ProductMetrics[] = [];
    for (let i = 0; i < cellCount; i++) {
        productCells.push(products[i] || null);
    }

    return (
        <table className={s.PricesTable}>
            <tr>
                {productCells?.map((product, index) => (
                    <td
                        key={
                            `${product?.aggregationSign}-${product?.cartProductID}-${product?.count}` ||
                            index
                        }
                    >
                        {product?.current && (
                            <>
                                Current
                                <br />
                            </>
                        )}
                        {(product?.threshold || 0) / 100 || '-'}{' '}
                        <>
                            ({(product?.mD || 0) / 100 || '-'})<br />
                        </>
                        {cartType === 'star' ? (
                            <>
                                Stars:{' '}
                                {prepareStarsForView(product.stars) || '-'} (
                                {prepareStarsForView(product.cartStars) || '-'})
                                <br />
                            </>
                        ) : null}
                        {product?.aggregationSign ? (
                            <Paragraph
                                copyable={{
                                    text: product?.aggregationSign,
                                }}
                            >{`AS: ${product?.aggregationSign.slice(
                                0,
                                3
                            )}...${product?.aggregationSign.slice(
                                -3
                            )}`}</Paragraph>
                        ) : (
                            'AS: -'
                        )}
                    </td>
                ))}
            </tr>
        </table>
    );
};

const CartsPage = () => {
    const { RangePicker } = DatePicker;
    const { shopId } = useParams<{ shopId: string }>();
    const [rangeDates, setRangeDates] = useState([
        dayjs().subtract(10, 'days').format('YYYY-MM-DD'),
        dayjs().add(1, 'days').format('YYYY-MM-DD'),
    ]);
    const [isWithoutCartData, setWithoutCartData] = useState(false);
    const [isRefetching, setRefetching] = useState(false);
    const options = {
        context: { linkType: LinkType.SuperAdmin },
        variables: { shopID: shopId, begin: rangeDates[0], end: rangeDates[1] },
        client: graphqlClientSuperAdmin,
    };

    const [isCartDataModalVisible, setCartDataModalVisible] = useState<
        string | false
    >(false);

    const { loading, data, refetch } = useQuery<
        CartOrderMetricsQuery,
        CartOrderMetricsQueryVariables
    >(ADMIN_GET_CART_METRICS, options);

    const searchInput = useRef<InputRef>(null);

    if (loading || isRefetching) {
        return (
            <div className={common.LoadingCover} style={{ display: 'flex' }}>
                <PercentageLoader />
            </div>
        );
    }

    const onChangeDate = (begin: string, end: string) => {
        setRangeDates([begin as string, end as string]);
        setRefetching(true);
        refetch({
            ...options.variables,
            begin: begin,
            end: end,
        }).finally(() => setRefetching(false));
    };

    const getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({
            setSelectedKeys,
            selectedKeys,
            confirm,
            clearFilters,
        }) => (
            <div
                style={{
                    padding: 8,
                    maxWidth: dataIndex === 'cartID' ? 140 : 400,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) =>
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                    }
                    onPressEnter={() =>
                        handleSearch(
                            selectedKeys as string[],
                            confirm,
                            dataIndex
                        )
                    }
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space wrap>
                    <Button
                        type="primary"
                        onClick={() =>
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex
                            )
                        }
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => {
                            clearFilters && handleReset(clearFilters);
                            handleSearch(
                                selectedKeys as string[],
                                confirm,
                                dataIndex
                            );
                        }}
                        size="small"
                        style={{ width: 90 }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{ color: filtered ? '#1890ff' : undefined }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes((value as string).toLowerCase()),
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const carts = groupBy<SingleMetrics | undefined>(
        (data?.cartOrderMetrics || []).flatMap((metric) => metric.metricsSet),
        (metric) => metric?.cartID
    );

    const withoutCartData = (
        Object.entries(carts)?.find(
            ([key, _]) => key == 'withoutCart'
        )?.[1] as any
    )?.map((val) => ({
        key: val.orderID,
        ...val,
        ...val.productsMetrics?.[0],
        date: val.date?.split(' ')?.[0] + ' ' + val.date?.split(' ')?.[1],
    }));

    const mainData = Object.entries(carts)
        ?.filter(([key, _]) => key != 'withoutCart')
        .map(([key, val]: [string, any]) => {
            const lastStep = val[val.length - 1] as SingleMetrics;
            const cartSingleMetrics = carts[key] as SingleMetrics[];
            const groupedDisplayPricesByAs = groupBy(
                cartSingleMetrics.flatMap((event, index) =>
                    event.productsMetrics?.map((tp2) => ({
                        ...tp2,
                        index,
                        event,
                    }))
                ),
                (item) =>
                    `${item?.aggregationSign}-${item?.cartProductID}-${item?.count}`
            );
            const displayPricesTable = Object.keys(groupedDisplayPricesByAs)
                .sort((a, b) => (a.length > b.length ? -1 : 1))
                .map(
                    (as) =>
                        groupedDisplayPricesByAs[as][
                            groupedDisplayPricesByAs[as].length - 1
                        ]
                );
            const maxIndex = Math.max(
                ...displayPricesTable.map((obj) => obj?.index || -Infinity)
            );

            const res = {
                key: lastStep.cartID,
                cartID: lastStep.cartID,
                orderID: lastStep.orderID || '-',
                cartSize: lastStep.cartSize,
                cartPrice: lastStep.cartPrice,
                cartCartFirstActionDate: val[0].date,
                checkGap: lastStep.checkGap
                    ? Math.round(lastStep.checkGap * 1000) / 1000 / 100
                    : 0,
                checkMD: lastStep.checkMD,
                gain: lastStep.gain
                    ? Math.round(lastStep.gain * 1000) / 1000 / 100
                    : 0,
                totalBonus: lastStep.totalBonus,
                tradeDiscount: lastStep.tradeDiscount
                    ? Math.round(lastStep.tradeDiscount * 1000) / 1000 / 100
                    : 0,
                juke: lastStep.juke?.toString().toLowerCase() || '',
                deltaTime: val[0].deltaTime,
                discount: lastStep.discount
                    ? Math.round(lastStep.discount * 1000) / 1000 / 100
                    : 0,
                productsInCartBeforeTrade: lastStep.productsInCartBeforeTrade,
                displayPriceLTPriceCount: lastStep.displayPriceLTPriceCount,
                displayPrice: getFinalDisplayPrices(
                    displayPricesTable,
                    cellsCount(
                        data?.cartOrderMetrics?.flatMap(
                            (item) => item.metricsSet
                        )
                    ),
                    lastStep.cartType as CartTypeEnum
                ),
                maximumProductsPricesLength: maxIndex,
            };

            res['steps'] = val.length;
            return res;
        })
        .sort(
            (a, b) =>
                new Date(b.cartCartFirstActionDate).getTime() -
                new Date(a.cartCartFirstActionDate).getTime()
        );

    const columns = (obj, fixed?: string[], width?: number) =>
        Object.keys(obj).map((key) => {
            let params: any = {
                title: () => (
                    <Popover placement={'bottomLeft'} content={obj[key]}>
                        <span style={{ width: '100%', display: 'block' }}>
                            {key}
                        </span>
                    </Popover>
                ),
                dataIndex: key,
                key,
                width: width || 140,
            };

            !['actionName', 'cartID', 'orderID', 'juke', 'date'].includes(
                key
            ) && (params = { ...params, sorter: (a, b) => a[key] - b[key] });
            fixed?.includes(key) && (params = { ...params, fixed: true });
            key === 'displayPrice' &&
                (params = {
                    ...params,
                    width: maximumProductsPricesLength(
                        data?.cartOrderMetrics?.flatMap(
                            (item) => item.metricsSet
                        )
                    ),
                });
            key === 'orderID' &&
                (params = {
                    ...params,
                    render: (text) => (
                        <Paragraph
                            copyable={{ text }}
                            className={s.paragraphEllipsis}
                        >
                            <span className={s.cartIdCell}>{text}</span>
                        </Paragraph>
                    ),
                });
            key === 'cartID' &&
                (params = {
                    ...params,
                    fixed: true,
                    ...getColumnSearchProps('cartID'),
                    render: (text) => (
                        <Paragraph
                            copyable={{ text }}
                            className={s.paragraphEllipsis}
                        >
                            <span className={s.cartIdCell}>{text}</span>
                        </Paragraph>
                    ),
                });
            key === 'cartPrice' &&
                (params = { ...params, render: (data) => getPrice(data) });
            key === 'actionName' &&
                (params = {
                    ...params,
                    fixed: true,
                    render: (text) => (
                        <Tag color={ActionColor[text]}>{ActionAlias[text]}</Tag>
                    ),
                });
            key === 'datetime' &&
                (params = {
                    ...params,
                    width: 220,
                    render: (_, localData) => {
                        const events = data?.cartOrderMetrics
                            ?.flatMap((item) => item.metricsSet)
                            .filter(
                                (item) => item?.cartID === localData.cartID
                            );

                        return (
                            <div>
                                <div>{`1: ${
                                    events?.[0]?.date?.split('.')?.[0]
                                }`}</div>
                                <div>{`${events?.length}: ${
                                    events?.[events?.length - 1]?.date?.split(
                                        '.'
                                    )?.[0]
                                }`}</div>
                            </div>
                        );
                    },
                });
            return params;
        });

    return (
        <>
            <Modal
                width={'auto'}
                onCancel={() => setCartDataModalVisible(false)}
                visible={Boolean(isCartDataModalVisible)}
                footer={null}
            >
                {isCartDataModalVisible && (
                    <CartEventsTable
                        eventsData={
                            carts[isCartDataModalVisible] as SingleMetrics[]
                        }
                        cartType={
                            carts[isCartDataModalVisible]?.[0]
                                ?.cartType as CartTypeEnum
                        }
                    />
                )}
            </Modal>
            <div style={{ margin: '20px 0' }}>
                <RangePicker
                    allowClear={false}
                    value={[dayjs(rangeDates[0]), dayjs(rangeDates[1])]}
                    style={{ marginRight: 30 }}
                    onChange={(_, dates) => onChangeDate(dates[0], dates[1])}
                />
                <Checkbox
                    onChange={() => setWithoutCartData(!isWithoutCartData)}
                >
                    Заказы без корзин
                </Checkbox>
            </div>
            <Table
                className={'simple-table'}
                columns={
                    isWithoutCartData
                        ? columns(descWithoutCart, ['orderID'])
                        : columns(desc, ['cartID'])
                }
                dataSource={isWithoutCartData ? withoutCartData : mainData}
                pagination={{ defaultPageSize: 100, position: ['topRight'] }}
                scroll={{ y: 450 }}
                onRow={(record) => {
                    return {
                        onClick: () => setCartDataModalVisible(record.cartID),
                    };
                }}
            />
        </>
    );
};

export default CartsPage;
